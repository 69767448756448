import { ENDPOINTS } from "../constants/endpoints";
import { Nearby, RoomCategory } from "../types/RoomCategory";
import { apiInstance, handleErrors } from "./api";


// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace API_ROOM_CATEGORY {

    export async function GetRoomCategory(roomId: string): Promise<RoomCategory> {
        try {
            const response = await apiInstance.get(`${ENDPOINTS.ROOM_CATEGORY}/${roomId}`);
            return response.data as RoomCategory;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function GetRoomCategoryNearby(roomId: string): Promise<Nearby> {
        try {
            const response = await apiInstance.get(ENDPOINTS.ROOM_CATEGORY_NEARBY.replace("{roomId}", roomId));
            return response.data as Nearby;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

}
