import { format } from "date-fns";
import { Status } from "../components/UserProfile/Reservation/types";
import { Urls, getUrl } from "../constants/urls";
import { PaymentReservation } from "../types/Reservation";
import { convertToUrlParams } from "./search";

type ReservationUrlProps = {
    _id?: string;
    checkinDate?: Date;
    checkoutDate?: Date;
    status?: string;
    language: string;
    page: number;
};

const SEARCH_URL_DATE_FORMAT = "yyyy-MM-dd";

export const getStatusClass = (status: string) => {
    let statusClass = "p-2 text-center rounded text-white text-xs lg:text-sm uppercase ";
    switch (status) {
        case Status.APPROVED:
            statusClass += "bg-green-500";
            break;
        case Status.PAID:
            statusClass += "bg-green-500";
            break;
        case Status.PENDING_APPROVAL:
        case Status.PENDING_PAYMENT:
            statusClass += "bg-primary-500";
            break;
        case Status.CANCELED:
        case Status.DECLINED:
            statusClass += "bg-red-500";
            break;
        default:
            statusClass += "bg-gray-500";
            break;
    }
    return statusClass;
};

export const getPriceWithCurrency = (lang: string, price: number) => {
    const priceInEuros = price / 100;
    const formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const formattedPrice = formatter.format(priceInEuros);
    return formattedPrice;
};

// calculate the total grossTotalAmountForGuest
export const calculateTotal = (payments: PaymentReservation[]) => {
    const total = payments.reduce((accumulator, payment) => {
        return accumulator + payment.grossTotalAmountForGuest;
    }, 0);

    return total;
};

export function generateReservationUrl({ ...rest }: ReservationUrlProps) {
    const params: Record<string, string> = {};

    params.page = rest.page.toString();

    if (rest.checkinDate) {
        params.checkinDate = format(rest.checkinDate, SEARCH_URL_DATE_FORMAT);
    }

    if (rest.checkoutDate) {
        params.checkoutDate = format(rest.checkoutDate, SEARCH_URL_DATE_FORMAT);
    }

    if (rest._id && rest._id !== "") {
        params._id = rest._id;
    }

    if (rest.status && rest.status !== "statusAny") {
        params.status = rest.status;
    }

    const reservationUrl = convertToUrlParams(`/reservation`, params);
    return reservationUrl;
}

export const generateMessageSlug = (reservationID: string, guestID: string, propertyID: string, lang: string) => {
    return getUrl(Urls.messages.index, lang, null, `slug=${reservationID}/${guestID}/${propertyID}`);
};
