import { useLanguage } from "@/lib/hooks/useLanguage";
import DatePickerInput from "../DatePicker";
import GuestPickerInput from "../GuestPicker";
import { useTranslation } from "@/lib/i18n/client";
import { useEffect, useRef, useState } from "react";
import { addDays, isAfter, isBefore } from "date-fns";
import { MAXIMUM_NUMBER_OF_STAY_DAYS, MINIMUM_NUMBER_OF_STAY_DAYS } from "@/lib/constants/datepicker";
import { useIsFirstRender } from "@uidotdev/usehooks";
import { defaultGuests } from "@/lib/constants/search";
import { RoomUnavailableDays } from "@/lib/types/Search";
import ReservationDataReadonly from "./ReservationDataReadonly";
import { useQueryParams } from "@/lib/hooks/useQueryParams";
import { usePathname, useRouter } from "next/navigation";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";
import { useAtomValue } from "jotai";
import { authAtom } from "@/lib/jotai/auth/authStore";

export type ReservationInfo = {
    checkIn: Date;
    checkOut: Date;
    numGuests?: number;
};

export type ReservationDataProps = {
    maxOccupancy: number;
    onReservationChange: (data: ReservationInfo) => void;
    mode?: "edit" | "readonly";
    unavailableDays?: RoomUnavailableDays;
    guestCounReservation?: number;
} & ReservationInfo;

const ReservationData = ({
    unavailableDays,
    onReservationChange,
    maxOccupancy,
    mode = "edit",
    guestCounReservation = 1,
    ...props
}: ReservationDataProps) => {
    const [checkIn, setCheckIn] = useState(props.checkIn);
    const [checkOut, setCheckOut] = useState(props.checkOut);
    const checkOutRef = useRef<HTMLInputElement>(null);
    const checkInRef = useRef<HTMLInputElement>(null);
    const guestRef = useRef<HTMLInputElement>(null);
    const [minCheckOutMinDate, setMinCheckoutDate] = useState<Date>();
    const [checkoutMaxDate, setCheckoutMaxDate] = useState<Date>();
    const isFirstRender = useIsFirstRender();
    const queryParams = useQueryParams();
    const pathName = usePathname();
    const router = useRouter();
    const { isLoggedIn, userInfo } = useAtomValue(authAtom);
    const guestQueryParam =
        mode === "readonly" ? guestCounReservation : parseInt(queryParams.get("guestCount") as string);

    const lang = useLanguage();
    const { t: tSearch } = useTranslation(lang, "search");

    const onCheckInChange = (checkIn: Date) => {
        if (!isFirstRender) {
            setCheckIn(checkIn);
            checkOutRef.current?.click();
            checkOutRef.current?.focus();
        }
    };

    const onCheckOutChange = (checkOutDate: Date) => {
        if (!isFirstRender) {
            setCheckOut(checkOutDate);
            guestRef.current?.click();
            guestRef.current?.focus();
        }
    };

    useEffect(() => {
        if (!isFirstRender) {
            onReservationChange({
                checkIn: checkIn,
                checkOut: checkOut,
                numGuests: guestQueryParam || defaultGuests,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkIn, checkOut, isFirstRender]);

    useEffect(() => {
        const minCheckoutDate = checkIn ? addDays(checkIn, MINIMUM_NUMBER_OF_STAY_DAYS) : undefined;
        setMinCheckoutDate(minCheckoutDate);
        setCheckoutMaxDate(checkIn ? addDays(checkIn, MAXIMUM_NUMBER_OF_STAY_DAYS) : undefined);
        const nextMonth = addDays(checkIn, MINIMUM_NUMBER_OF_STAY_DAYS);
        // only change checkout date if we're below min number of stays or above max number of stays
        if (
            checkOut instanceof Date &&
            (isBefore(checkOut, nextMonth) || (checkoutMaxDate && isAfter(checkOut, checkoutMaxDate)))
        ) {
            setCheckOut(nextMonth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkIn]);

    useEffect(() => {
        try {
            if (
                unavailableDays?.checkin.length ||
                unavailableDays?.checkout.length ||
                typeof unavailableDays === "undefined"
            ) {
                GoogleTagManagerEvents.clickOnChangeDateButtons(lang, isLoggedIn ? userInfo._id : undefined);
            }
        } catch (error) {
            console.log("Failed to send GTM event clickOnChangeDateButtons due to:", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unavailableDays]);

    return mode === "edit" ? (
        <div className="flex flex-col gap-5">
            <div className="flex flex-row justify-stretch gap-5">
                <DatePickerInput
                    placeholder={tSearch("check-in")}
                    className="grow"
                    onChangeDate={onCheckInChange}
                    initialValue={checkIn}
                    ref={checkInRef}
                    unavailableDays={unavailableDays?.checkin}
                    isMobileModalOpen={true}
                />
                <DatePickerInput
                    placeholder={tSearch("check-out")}
                    type="text"
                    className="grow"
                    arrowDirection="right"
                    ref={checkOutRef}
                    initialValue={checkOut}
                    onChangeDate={onCheckOutChange}
                    showMonthSelector
                    minDate={minCheckOutMinDate}
                    maxDate={checkoutMaxDate}
                    unavailableDays={unavailableDays?.checkout}
                />
            </div>
            <GuestPickerInput
                guestCount={guestQueryParam}
                lang={lang}
                ref={guestRef}
                maxGuests={maxOccupancy}
                placeholder={tSearch("guestpicker.guests", { count: guestQueryParam })}
                type="text"
                className="basis-2/12"
                setGuestCount={(count) => {
                    const carryOverParams: { [key: string]: string } = {};
                    for (const [key, value] of queryParams.entries()) {
                        carryOverParams[key] = value;
                    }
                    const duplicateParams = ["roomId", "startDate", "endDate", "guestCount"];
                    duplicateParams.forEach((duplicateKey: string) => {
                        delete carryOverParams[duplicateKey];
                    });
                    const urlParams = new URLSearchParams();
                    for (const [key, value] of Object.entries(carryOverParams)) {
                        urlParams.set(key, value);
                    }
                    urlParams.set("roomId", queryParams.get("roomId") as string);
                    urlParams.set("startDate", queryParams.get("startDate") as string);
                    urlParams.set("endDate", queryParams.get("endDate") as string);
                    urlParams.set("guestCount", count.toString());
                    router.replace(`${pathName}?${urlParams.toString()}`, { scroll: false });
                }}
            />
        </div>
    ) : (
        <ReservationDataReadonly checkIn={checkIn} checkOut={checkOut} numGuests={guestQueryParam ?? 0} />
    );
};

export default ReservationData;
