import { RoomDetailsProps } from "@/app/[lang]/(with-footer)/rooms/RoomDetails";
import { generatePhotoUrl } from "./search";
import { BookingType } from "../types/Search";
import { RoomCategory } from "../types/RoomCategory";
import { translateISOCountry } from "../i18n/client";


export function convertToRoomDetailsProps(roomDetails: RoomCategory, lang: string): RoomDetailsProps {
    let address = [translateISOCountry(roomDetails.property.address.country, lang)];
    if (roomDetails.property.address.city) {
        address = [roomDetails.property.address.city, ...address];
    }
    return {
        // custom
        address: address.join(", "),
        images: roomDetails.photos.concat(roomDetails.property.photos).map((photo) => {
            return {
                title: photo.description,
                url: generatePhotoUrl(photo),
                _id: photo._id,
            };
        }),
        location: {
            lat: roomDetails.property.location.coordinates[1],
            lng: roomDetails.property.location.coordinates[0],
        },
        rulesAllowed: Object.entries(roomDetails.rules).filter(r => typeof r[1] === "boolean" && r[1]).map(r => r[0]),
        rulesNotAllowed: Object.entries(roomDetails.rules).filter(r => typeof r[1] === "boolean" && !r[1]).map(r => r[0].replace("Allowed", "NotAllowed")),

        // from RoomCategory
        ...roomDetails,

        // overwrite additional data
        rules: {
            ...roomDetails.rules,
            additional: roomDetails.rules.additional === "false" ? undefined : roomDetails.rules.additional,
        },

        // from BadgesProps
        isInstantBooking: roomDetails.bookingType === BookingType.Instant,
        isSuperHost: false,
        isFeatured: roomDetails.featured,
    };
}
