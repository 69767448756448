import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { format } from "date-fns";
import { DATEPICKER_DATE_FORMAT } from "@/lib/constants/datepicker";
import Input from "../Input";
import { getLanguageData } from "@/lib/i18n/settings";
import { ReservationDataProps } from "./ReservationData";
import { faCalendar, faUser } from "@fortawesome/pro-regular-svg-icons";

export type ReservationDataReadonlyProps = Required<Pick<ReservationDataProps, "checkIn" | "checkOut" | "numGuests">>;

const ReservationDataReadonly = ({ checkIn, checkOut, numGuests }: ReservationDataReadonlyProps) => {
    const lang = useLanguage();
    const locale = getLanguageData(lang);
    const { t: tSearch } = useTranslation(lang, "search");

    const formatDate = (dt: Date) => {
        if (dt) {
            console.log(dt);
            return format(dt, DATEPICKER_DATE_FORMAT, { locale: locale.dateLocale });
        }
        return "";
    };

    return (
        <div className="flex flex-col gap-5 p-3">
            <div className="flex flex-row justify-stretch gap-5">
                <Input
                    icon={faCalendar}
                    iconDirection="left"
                    value={formatDate(checkIn)}
                    className="disabled grow"
                    readOnly={true}
                    disabled={true}
                    placeholder={tSearch("check-in")}
                />
                <Input
                    icon={faCalendar}
                    iconDirection="left"
                    value={formatDate(checkOut)}
                    className="disabled grow"
                    readOnly={true}
                    disabled={true}
                    placeholder={tSearch("check-out")}
                />
            </div>
            <Input
                icon={faUser}
                iconDirection="left"
                value={numGuests}
                className="disabled"
                readOnly
                disabled={true}
                placeholder={tSearch("guestpicker.guests", { count: numGuests })}
            />
        </div>
    );
};

export default ReservationDataReadonly;
