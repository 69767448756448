import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { formatMoney } from "@/lib/utils/checkout";
import CloudImage from "../CloudImage";
import Text from "../Text";
import { cloudinaryCoyotivLoader } from "@/lib/setup/cloudinaryLoader";

export type RoomSummaryHeadProps = {
    roomTitle: string;
    imageUrl?: string;
    price: number;
    isAvailable?: boolean;
    showFrom?: boolean;
    bookingInDays?: number;
    isError: boolean;
};

const RoomSummaryHead = ({
    roomTitle,
    price,
    imageUrl,
    isAvailable = true,
    showFrom = false,
    bookingInDays,
    isError = false,
}: RoomSummaryHeadProps) => {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "checkout");
    const { t: tSearch } = useTranslation(lang, "search");

    return (
        <div className="flex flex-col text-white">
            {imageUrl && (
                <div className="relative w-full h-52">
                    <CloudImage loader={cloudinaryCoyotivLoader} src={imageUrl} alt="room" className="object-cover" />
                    <div className="absolute bottom-0 w-full">
                        <div className="grow bg-gradient-to-t from-black px-4 pt-4">
                            <Text className="font-bold text-xl">{roomTitle}</Text>
                        </div>
                        <div className="font-semibold text-2xl bg-black px-4 pb-2">
                            {showFrom && <span className="text-base mr-2">{tSearch("room.from")}</span>}
                            {isAvailable ? formatMoney(lang, price) : "-"}
                            <span className="text-base">/{t("pricePerMonth")}</span>
                        </div>
                    </div>
                </div>
            )}
            {!imageUrl && (
                <div className="font-semibold text-2xl bg-black p-4">
                    {showFrom && price > 0 && <span className="text-base mr-2">{tSearch("room.from")}</span>}
                    {/*{isAvailable ? formatMoney(lang, price) : "-"}*/}
                    {isError ? (
                        t("errorMessage")
                    ) : (
                        <>
                            {price === 0 ? t("calculatingPrice") : isAvailable ? formatMoney(lang, price) : "-"}
                            {price > 0 && (
                                <span className="text-base">
                                    /{(bookingInDays ?? 30) < 30 ? t("totalStay") : t("pricePerMonth")}
                                </span>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default RoomSummaryHead;
