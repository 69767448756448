import { LanguageParams } from "@/lib/types/PageProps";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";

export enum Status {
    statusAny = "statusAny",
    APPROVED = "APPROVED",
    PENDING_APPROVAL = "PENDING-APPROVAL",
    PENDING_PAYMENT = "PENDING-PAYMENT",
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
    DECLINED = "DECLINED",
    PAID = "paid",
}

export type StatusPickerInputProps = {
    className?: string;
    customHeight?: string;
    currentStatus?: string;
    handleStatusChange: (value: string) => void;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "ref"> &
    LanguageParams;
