import { format } from "date-fns";
import { Language } from "../i18n/settings";
import { RoomSummaryUrlProps } from "./search";
import { Urls, getUrl } from "../constants/urls";
import {
    CheckoutBillingBusinessFormData,
    CheckoutBillingPrivateFormData,
    CheckoutGuestFormData,
} from "../services/checkoutService";
import { SEARCH_URL_DATE_FORMAT } from "../constants/search";

export type Visitor = {
    name: string;
    email: string;
    phoneNumber: string;
    type: "adult" | "child";
};

export type PaymentPreference = {
    provider: "Stripe" | "PayPal" | string;
};

export type PaymentPreferencePaypal = PaymentPreference;
export type PaymentPreferenceStripe = {
    paymentMethodId: string;
} & PaymentPreference;

export type InvoicePrivate = {
    type: "private" | "company";
    address: CheckoutBillingPrivateFormData;
    phoneNumbers: string[];
};

export type InvoiceCompany = {
    companyName: string;
    taxNumber: string;
} & InvoicePrivate;

export function formatMoney(lang: Language, amount: number) {
    const currencyStyle: Intl.NumberFormatOptions = {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };
    return amount.toLocaleString(lang, currencyStyle);
}

export function buildCheckoutUrl(
    lang: Language,
    { roomId, startDate, endDate, guestCount }: RoomSummaryUrlProps,
    carryOverParams: { [key: string]: string } = {},
): string {
    const carryOverCopy = { ...carryOverParams };
    const params = ["roomId", "startDate", "endDate", "guestCount"];
    const urlParams = new URLSearchParams();
    params.forEach((duplicateKey: string) => {
        delete carryOverCopy[duplicateKey];
    });
    for (const [key, value] of Object.entries(carryOverCopy)) {
        urlParams.set(key, value);
    }
    urlParams.set("roomId", roomId);
    urlParams.set("startDate", format(startDate, SEARCH_URL_DATE_FORMAT));
    urlParams.set("endDate", format(endDate, SEARCH_URL_DATE_FORMAT));
    urlParams.set("guestCount", guestCount.toString());
    const queryString = urlParams.toString();
    return getUrl(Urls.checkout.index, lang, {}, queryString);
}

export function buildVisitorsFromGuestData(guestData: Required<CheckoutGuestFormData>): Visitor[] {
    const visitors: Visitor[] = [];
    visitors.push({
        email: guestData.email ?? "",
        name: `${guestData.firstName} ${guestData.lastName}`,
        phoneNumber: guestData.guestPhone ?? "",
        type: "adult",
    });
    guestData.additionalGuests.forEach((additionalGuest) => {
        visitors.push({
            email: guestData.email ?? "",
            name: `${additionalGuest.firstName} ${additionalGuest.lastName}`,
            phoneNumber: guestData.guestPhone ?? "",
            type: "adult",
        });
    });
    return visitors;
}

export function buildInvoiceData(
    guestData: CheckoutGuestFormData,
    billingData: CheckoutBillingPrivateFormData | CheckoutBillingBusinessFormData,
): InvoicePrivate | InvoiceCompany {
    if ("vatId" in billingData) {
        const { companyName, vatId, ...address } = billingData;
        return {
            type: "company",
            companyName,
            taxNumber: vatId,
            address: address,
            phoneNumbers: [guestData.guestPhone ?? ""],
        } as InvoiceCompany;
    } else {
        return {
            type: "private",
            address: billingData,
            phoneNumbers: [guestData.guestPhone ?? ""],
        } as InvoicePrivate;
    }
}

export function calculateRoomPricePerMonth(monthlyAveragePrice?: number, monthlyMultiplier?: number) {
    const multiplier = monthlyMultiplier ? (monthlyMultiplier === 0 ? 1 : monthlyMultiplier) : 1;
    return (monthlyAveragePrice ?? 0) / multiplier / 100;
}
