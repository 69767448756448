function SkeletonPrice({ isCheckoutMode }: { isCheckoutMode: boolean }) {
    return (
        <>
            <div
                className={`animate-pulse  ${
                    isCheckoutMode ? "" : "hidden"
                } w-full lg:flex flex-col justify-start drop-shadow-md gap-2 items-start`}
            >
                <div className="flex flex-col animate-pulse w-full bg-gray-100 justify-start items-start h-full border-b">
                    {isCheckoutMode ? (
                        <div className="flex animate-pulse bg-gray-300 h-52 w-full"></div>
                    ) : (
                        <div className="flex animate-pulse bg-gray-300 h-[51px] w-full"></div>
                    )}
                    <div className="flex flex-col justify-start items-start gap-5 p-5 w-full">
                        <div className="h-[40px] w-full animate-pulse rounded-md bg-gray-300"></div>
                        <div className="flex justify-start w-full flex-col gap-5 items-start">
                            <div className="flex w-full justify-between items-center gap-5">
                                <div className="animate-pulse bg-gray-300  rounded-md w-full h-[40px]"></div>
                                <div className="animate-pulse bg-gray-300  rounded-md w-full h-[40px]"></div>
                            </div>
                            <div className="animate-pulse  bg-gray-300  rounded-md w-full h-[40px]"></div>
                        </div>
                    </div>
                    {isCheckoutMode && (
                        <div className="flex flex-row w-full py-6 px-5 justify-between border-t">
                            <div className="animate-pulse  bg-gray-300  rounded-md w-full max-w-[180px] h-[50px]"></div>
                            <div className="animate-pulse  bg-gray-300 max-w-[180px]  rounded-md w-full h-[50px]"></div>
                        </div>
                    )}
                </div>
                {!isCheckoutMode && (
                    <div className="flex flex-col gap-5 justify-start items-start p-5 w-full border-b">
                        <div className="animate-pulse  bg-gray-300  rounded-md w-full h-[50px]"></div>
                        <div className="animate-pulse  bg-primary-100  rounded-md w-full h-[50px]"></div>
                        <div className="animate-pulse  bg-gray-300  rounded-md w-full h-[15px]"></div>
                    </div>
                )}

                {!isCheckoutMode && (
                    <div className="flex flex-col gap-5 justify-start items-start p-5 w-full">
                        <div className="animate-pulse  bg-gray-300  rounded-md w-full h-[50px]"></div>
                        <div className="animate-pulse  bg-gray-300  rounded-md w-full h-[20px]"></div>
                    </div>
                )}
            </div>
            {!isCheckoutMode && (
                <div className="lg:hidden flex flex-row  py-6 px-5 justify-between">
                    <div className="animate-pulse  bg-gray-300  rounded-md w-full max-w-[180px] h-[50px]"></div>
                    <div className="animate-pulse  bg-primary-100 max-w-[180px]  rounded-md w-full h-[50px]"></div>
                </div>
            )}
        </>
    );
}

export default SkeletonPrice;
