import { apiInstance, handleErrors } from "./api";
import { ENDPOINTS } from "../constants/endpoints";
import { ReservationDoc, ReservationResponse, ReservationSummary } from "../types/Reservation";
import { PaymentPreferencePaypal, PaymentPreferenceStripe, Visitor } from "../utils/checkout";
import { format } from "date-fns";

export type ReservationRequest = {
    visitors: Visitor[];
    checkinDate: Date;
    checkoutDate: Date;
    roomCategoryId: string;
    paymentPreferences: PaymentPreferenceStripe | PaymentPreferencePaypal;
    cookiePreferences: string;
    googleClickData?: {
        googleClickId?: string;
    };
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace API_RESERVATION {
    export const RESERVATION_API_DATE_FORMAT = "dd.MM.yyyy";
    export const RESERVATION_API_DATE_FORMAT_RESPONSE = "yyyy-MM-dd";

    export async function getUserReservations() {
        try {
            const response = await apiInstance.get(ENDPOINTS.RESERVATION);
            return response.data as ReservationResponse;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function getUserReservationDetails(id: string) {
        try {
            const response = await apiInstance.get(ENDPOINTS.RESERVATION + id);
            return response.data as ReservationDoc;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function getUserFilteredReservations(generatedURL: string) {
        try {
            const response = await apiInstance.get(generatedURL);
            return response.data as ReservationResponse;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function cancelReservationBooking(id: string) {
        const data = { action: "cancel" };
        try {
            const response = await apiInstance.patch(`${ENDPOINTS.RESERVATION}/${id}`, data);
            return response.data as ReservationDoc;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function createReservation(reservation: ReservationRequest): Promise<ReservationSummary> {
        try {
            const { checkinDate, checkoutDate, ...rest } = reservation;
            const response = await apiInstance.post(ENDPOINTS.RESERVATION, {
                checkinDate: format(checkinDate, RESERVATION_API_DATE_FORMAT),
                checkoutDate: format(checkoutDate, RESERVATION_API_DATE_FORMAT),
                ...rest,
            });
            return response.data as ReservationSummary;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    type PaymentPreferencesPayload = {
        paymentPreferences: {
            provider: string;
            paymentMethodId: string;
            paypalCaptureId?: string;
        };
    };

    /**
     * Update the payments on a reservation (one of use cases would be to retry payment if declined)
     *
     * @param id Reservation Id
     * @param provider Provider at the moment can be Stripe or PayPal
     * @param paymentMehtodId Stripe payment method Id
     * @param paypalCaptureId  Related to Paypal
     * @returns
     */
    export async function updatePaymentPreferences(
        id: string,
        provider: "Stripe" | "PayPal", //We need to make this an enum since it is case sensitive "Stripe"
        paymentMehtodId: string | null,
        paypalCaptureId: string | null = null,
    ): Promise<ReservationSummary> {
        const payload = {
            paymentPreferences: {
                provider: provider,
            },
        } as PaymentPreferencesPayload;

        if (paypalCaptureId) payload.paymentPreferences.paypalCaptureId = paypalCaptureId;
        if (paymentMehtodId) payload.paymentPreferences.paymentMethodId = paymentMehtodId;

        try {
            const response = await apiInstance.put(`${ENDPOINTS.RESERVATION}/${id}/payment-preferences`, payload);
            return response.data as ReservationSummary;
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }
}
